import React from 'react'
import Footer from '../components/Footer'
import Menu from '../components/Menu'
import Layout from '../components/layout'
import Header from '../components/Header'
import BreadCrumb from '../components/Share/BreadCrumb'
const breadcrumb = [
  {
    link: '/',
    title: 'Home',
  },
  {
    title: 'Allgemeine Geschäftsbedingungen',
  },
]

const ToS = () => {
  return (
    <Layout>
      <Header page="tos" />
      <Menu />
      <div className="big-background-image pb-8  bg-lendis-black bg-no-repeat bg-top lg:pb-40 pt-40 px-3">
        <div className="container mx-auto text-white">
          <h1 className="font-bold lg:text-6xl px-8 text-3xl mb-5">
            Allgemeine Geschäftsbedingungen
          </h1>
        </div>
        <BreadCrumb breadCrumbItems={breadcrumb} textPage />
        <div className="bg-white mx-auto container">
          <div className="py-12">
            <div className="ml-8 mt-8 lg:ml-16 lg:mr-16">
              <h2 className="text-2xl uppercase">
                Allgemeine Geschäftsbedingungen der Lendis Assets GmbH
              </h2>
              <p className="font-semibold">Stand: 20.11.2020</p>
              <ol className="start-list">
                <li>
                  <h4 className="uppercase font-semibold mt-4">Allgemeines</h4>
                  <ol className="sub-list">
                    <li>
                      Diese allgemeinen Geschäftsbedingungen („AGB“) gelten für die
                      Vertragsbeziehung zwischen der Lendis Assets GmbH (<strong>„Lendis“</strong>)
                      und dem Kunden, gemeinsam <strong>„die Parteien“</strong>.
                    </li>
                    <li>
                      Gegenstand der Vertragsbeziehung sind die entgeltliche Gebrauchsüberlassung
                      von Büroeinrichtung, Elektronikartikeln und/oder anderen Mietsachen (
                      <strong>„Mietsachen“</strong>) und die Erbringung von Serviceleistungen, die
                      mit der Gebrauchsüberlassung der Mietsachen in Zusammenhang stehen.
                    </li>
                    <li>
                      Die AGB gelten ausschließlich für den gewerblichen Bereich. Lendis bietet ihre
                      Leistungen nicht für Verbraucher oder für Geschäftspartner an, für die das
                      zugrundeliegende Geschäft nicht in Ausübung ihrer gewerblichen oder
                      selbständigen beruflichen Tätigkeit erfolgt. Der Vertragsschluss erfolgt auf
                      Grundlage dieser AGB, sofern nicht besondere individuelle Abreden getroffen
                      werden. Abweichende oder ergänzende Bedingungen des Kunden werden nicht schon
                      Vertragsinhalt, wenn Lendis diesen nicht gesondert widerspricht.
                    </li>
                    <li>
                      Soweit in diesen Geschäftsbedingungen keine Regelung getroffen ist, gelten
                      nicht die allgemeinen Geschäftsbedingungen des Kunden, sondern die
                      gesetzlichen Regelungen.
                    </li>
                    <li>
                      Bestimmungen eines individuell vereinbarten Vertrages zwischen den Parteien
                      und seine Anlagen gehen diesen AGB vor. Ergänzend gelten jedoch diese AGB.
                    </li>
                    <li>
                      Lendis ist berechtigt, das Vertragsverhältnis einschließlich aller bestehenden
                      Rechte und Pflichten aus diesen AGB und dem jeweiligen Vertrag auch ohne
                      Mitwirkung des Kunden im Wege einer Vertragsübernahme mit schuldbefreiender
                      Wirkung auf eine andere Gesellschaft zu übertragen, die unter der
                      wirtschaftlichen Kontrolle von Lendis oder der mit Lendis im Sinne von §§ 15
                      ff. AktG verbundenen Lendis GmbH steht.
                    </li>
                  </ol>
                </li>
                <li>
                  <h4 className="uppercase font-semibold mt-4"> Vertragslaufzeit und Kündigung</h4>
                  <ol className="sub-list">
                    <li>
                      Die Vertragsmindestlaufzeit ergibt sich aus der individuellen Vereinbarung
                      zwischen Lendis und dem Kunden und beginnt mit Lieferung der Mietsache an den
                      Kunden.
                    </li>
                    <li>
                      Nach Ablauf der Vertragsmindestlaufzeit verlängert sich der Vertrag jeweils um
                      einen weiteren Monat, wenn er nicht spätestens am ersten Werktag eines
                      Vertragsmonats zum Ablauf desselben Vertragsmonats von einer Partei gekündigt
                      wird. Während der Vertragsmindestlaufzeit ist eine ordentliche Kündigung
                      ausgeschlossen. Das Recht zur außerordentlichen fristlosen Kündigung aus
                      wichtigem Grund gemäß § 543 BGB bleibt unberührt. Wichtige Gründe sind
                      insbesondere ein Zahlungsverzug des Kunden über zwei Monate oder die Eröffnung
                      eines Insolvenzverfahrens bzw. die Ablehnung der Eröffnung eines
                      Insolvenzverfahrens mangels Masse gegen den Kunden.
                    </li>
                    <li>Die Kündigung bedarf zu ihrer Wirksamkeit der Schriftform.</li>
                    <li>
                      Der Kunde nimmt zur Kenntnis, dass die vertraglich vereinbarte monatliche
                      Miete auf Basis einer im Angebot genannten, kalkulierten Laufzeit (
                      <strong>„kalkulierte Laufzeit“</strong>) berechnet wurde, welche in der Regel
                      länger ist als die Vertragsmindestlaufzeit. Endet der Vertrag aufgrund einer
                      Kündigung durch eine Partei vor Ablauf der kalkulierten Laufzeit (
                      <strong>„vorzeitige Kündigung“</strong>), so ist der Kunde verpflichtet,
                      Lendis so zu stellen, als ob von Anfang an die aufgrund der Kündigung
                      verkürzte Vertragslaufzeit (und die damit verbundene höhere monatliche Miete)
                      vereinbart worden wäre. Hierzu hat der Kunde eine einmalige Nachzahlung an
                      Lendis zu leisten, die in ihrer Höhe der Differenz zwischen der Gesamtmiete,
                      die gemäß Angebotsliste von Lendis für die verkürzte Mietzeit zu zahlen
                      gewesen wäre, und der unter diesem Vertrag für den Zeitraum bis zur
                      vorzeitigen Beendigung des Vertrags zu zahlenden Gesamtmiete entspricht (siehe
                      Kalkulationsübersicht zur realisierten Laufzeit im jeweiligen Angebot). Das
                      Gleiche gilt im Falle einer vorzeitigen Kündigung durch Lendis, die der Kunde
                      zu vertreten hat (z.B. außerordentliche Kündigung aufgrund Zahlungsverzugs des
                      Kunden).
                    </li>
                    <li>
                      Sofern und soweit verfügbar wird Lendis dem Kunden zum Ablauf der
                      Vertragsmindestlaufzeit (oder auch zu einem anderen Zeitpunkt) einen Austausch
                      einzelner oder mehrere Mietsachen gegen Mietsachen neuerer Art (z.B.
                      Nachfolgemodell eines gemieteten Elektronikartikels) anbieten. Entscheidet
                      sich der Kunde für das/die angebotene/n Austauschmodell/e, erhält er von
                      Lendis ein neues Vertragsangebot mit angepasster Miete und/oder angepassten
                      Gebühren. Mit Annahme des Vertragsangebots durch den Kunden entsteht zwischen
                      den Parteien ein neuer Vertrag mit neuer Vertragsmindestlaufzeit. Die neue
                      Vertragsmindestlaufzeit beginnt mit Lieferung des/der Austauschmodell/s an den
                      Kunden. Es ist nicht ausgeschlossen, dass es aufgrund von Lieferengpässen der
                      Hersteller zu einer verspäteten Lieferung oder nicht möglichen Lieferung
                      einzelner oder mehrerer Austauschmodelle kommen kann. Ausgetauschte Mietsachen
                      hat der Kunde innerhalb von 14 Tagen nach Lieferung der Austauschmodelle an
                      Lendis zurückzusenden.
                    </li>
                    <li>
                      Bei Beendigung des Vertrags ist der Kunde zur Rückgabe aller Mietsachen
                      verpflichtet; dies gilt nicht hinsichtlich solcher Mietsachen, die der Kunde
                      von Lendis käuflich erworben hat.
                    </li>
                    <li>
                      Erfolgt die Rückgabe der Mietsachen innerhalb eines laufenden Vertragsmonats,
                      so ist Lendis berechtigt den vollen Vertragsmonat, in dem die Rückgabe
                      erfolgt, in Rechnung zu stellen.
                    </li>
                    <li>
                      Erfolgt die Rückgabe der Mietsache nach Ablauf des letzten Vertragsmonats, so
                      ist Lendis berechtigt den zusätzlichen Leistungszeitraum in Rechnung zu
                      stellen. Dies gilt nicht, soweit Lendis die verspätete Rückgabe zu vertreten
                      hat.
                    </li>
                  </ol>
                </li>
                <li>
                  <h4 className="uppercase font-semibold mt-4">
                    Miete, Gebühren und Zahlungsbedingungen
                  </h4>
                  <ol className="sub-list">
                    <li>
                      Die vom Kunden zu zahlende Miete und sonstigen Gebühren ergeben sich aus der
                      individuellen Vereinbarung zwischen Lendis und dem Kunden basierend auf der
                      Angebotsliste von Lendis.
                    </li>
                    <li>
                      Die erste Zahlung in Höhe der monatlichen Miete sowie weiterer vereinbarter
                      sonstiger Gebühren ist für den ersten Vertragsmonat mit der Annahme des
                      Angebots fällig. Die Zahlung erfolgt durch Überweisung, per Lastschrift oder
                      nach Absprache zwischen den Parteien durch eine andere Zahlungsart.
                      Nachfolgende Zahlungen müssen spätestens sieben Tage nach Rechnungserhalt auf
                      dem von Lendis angegebenen Bankkonto eingegangen sein, es sei denn, zwischen
                      den Parteien ist eine andere Zahlungsart oder ein anderer Zahlungstermin
                      vereinbart worden. Andere Zahlungsfristen können abweichend davon zwischen den
                      Parteien vereinbart werden und bedürfen zur Wirksamkeit der Schriftform.
                    </li>
                    <li>
                      Bei Zahlungsverzug ist Lendis uneingeschränkt berechtigt, den entstandenen
                      Verzugsschaden (insbesondere Zinsen) bei dem Kunden geltend zu machen.
                    </li>
                    <li>
                      Der Kunde ist nicht befugt, Zahlungen zurückzuhalten. Der Kunde kann nur mit
                      Forderungen aufrechnen, die rechtskräftig festgestellt oder von Lendis
                      anerkannt worden sind. Der Kunde kann Ansprüche aus diesem Vertrag nur mit
                      vorheriger schriftlicher Zustimmung von Lendis an Dritte abtreten.
                    </li>
                  </ol>
                </li>

                <li>
                  <h4 className="uppercase font-semibold mt-4">
                    Lieferung und Montage; Abbau und Abholung; weitere SErviceleistungen
                  </h4>
                  <ol className="sub-list">
                    <li>
                      Die Mietsachen werden von Lendis zu dem vereinbarten Liefertermin an den
                      vereinbarten Standort geliefert und, soweit vertraglich vereinbart, dort
                      montiert. Der Kunde ist verpflichtet, die Lieferung zum vereinbarten
                      Liefertermin anzunehmen und, sofern eine Montage vereinbart ist, die Montage
                      durch Lendis oder einen von Lendis beauftragten Dritten zu ermöglichen. Kommt
                      der Kunde dieser Pflicht nicht nach, ist Lendis berechtigt, die Mietsachen auf
                      Kosten des Kunden einzulagern und dem Kunden die Kosten einer gescheiterten
                      Anlieferung oder Montage in Rechnung zu stellen; dies gilt nicht, wenn der
                      Kunde die Pflichtverletzung nicht zu vertreten hat. Im Wiederholungsfall ist
                      Lendis zudem berechtigt, den Vertrag aus wichtigem Grund zu kündigen. Möchte
                      der Kunde nach erfolgter Lieferung den Standort wechseln, ist Lendis
                      berechtigt, für erneute Lieferung und Montage eine Vergütung nach Aufwand zu
                      berechnen.
                    </li>
                    <li>
                      Grundsätzlich hat der Kunde die Mietsachen am vereinbarten Standort zu nutzen.
                      Beabsichtigt der Kunde, die Mietsachen an einem anderen Standort zu nutzen,
                      ist zuvor die schriftliche Erlaubnis von Lendis einzuholen. Lendis wird die
                      Erlaubnis nicht verweigern, wenn keine angemessenen Gründe entgegenstehen. Ein
                      angemessener Grund liegt insbesondere dann vor, wenn der Kunde den
                      ursprünglich vereinbarten Standort innerhalb eines kurzen Zeitraums mehrfach
                      verändert hat.
                    </li>
                    <li>
                      Es obliegt dem Kunden, die für die Installation und den Betrieb der Mietsachen
                      notwendigen Voraussetzungen an seinem Standort, insbesondere die für den
                      Betrieb elektronischer Geräte notwendige Elektroinstallation, auf eigene
                      Kosten bereitzustellen.
                    </li>
                    <li>
                      Der Kunde hat sicherzustellen, dass Lendis die Mietsache zum vereinbarten
                      Abholtermin innerhalb der üblichen Geschäftszeiten abbauen und abholen kann.
                    </li>
                    <li>
                      Lendis ist berechtigt, sich zur Erfüllung ihrer Leistungsverpflichtungen
                      Dritter zu bedienen.
                    </li>
                    <li>
                      Der Kunde verpflichtet sich, Lendis bei Erfüllung der vertraglichen Pflichten
                      im notwendigen Umfang kostenfrei zu unterstützen und alle in seiner
                      Betriebssphäre zur ordnungsgemäßen Auftragsausführung erforderlichen
                      Voraussetzungen zu schaffen.
                    </li>
                  </ol>
                </li>

                <li>
                  <h4 className="uppercase font-semibold mt-4">Mängel der Mietsache</h4>
                  <ol className="sub-list">
                    <li>
                      Die Mietsache wird dem Kunden in neuwertigem oder neuem Zustand überlassen.
                      Die sonstige vertraglich vereinbarte Beschaffenheit richtet sich nach der
                      Beschreibung der Mietsache inklusive Fotos in der Angebotsliste von Lendis.
                      Die Mietsache ist frei von Mängeln, wenn sie im Zeitpunkt der Überlassung der
                      vertraglich vereinbarten Beschaffenheit entspricht.
                    </li>
                    <li>
                      Die verschuldensunabhängige Haftung von Lendis bei anfänglichen Mängeln ist
                      ausgeschlossen. Lendis haftet insoweit nur bei grober Fahrlässigkeit oder
                      Vorsatz.
                    </li>
                    <li>
                      Mängel sind vom Kunden unverzüglich nach Bekanntwerden des Mangels schriftlich
                      bei Lendis anzuzeigen. Für durch verspätete Mängelrüge verursachte Schäden
                      haftet der Kunde gegenüber Lendis.
                    </li>
                    <li>
                      Liegt ein Mangel vor, beseitigt Lendis den Mangel innerhalb angemessener Zeit
                      nach eigener Wahl durch Reparatur der Mietsache oder durch Lieferung einer
                      neuen Sache. Liegt ein nur unerheblicher Mangel vor, der den vertragsgemäßen
                      Gebrauch der Mietsache nicht einschränkt, besteht kein Anspruch des Kunden auf
                      Reparatur der Mietsache oder Lieferung einer neuen Sache. Das Recht des Kunden
                      zur Minderung bleibt unberührt.
                    </li>
                    <li>
                      Stellt sich heraus, dass ein Mangel trotz Meldung des Kunden nicht vorliegt,
                      ist Lendis berechtigt, die mit der versuchten Beseitigung in Verbindung
                      stehenden Aufwände zu berechnen.
                    </li>
                    <li>
                      Die Ansprüche des Kunden wegen Mängeln verjähren 12 Monate nach Übergabe, es
                      sei denn, Lendis hat den Mangel arglistig verschwiegen. Dann gelten die
                      gesetzlichen Verjährungsfristen.
                    </li>
                  </ol>
                </li>

                <li>
                  <h4 className="uppercase font-semibold mt-4">Haftung von Lendis</h4>
                  <ol className="sub-list">
                    <li>
                      Für Schäden, die von Lendis vorsätzlich oder grob fahrlässig herbeigeführt
                      werden, haftet Lendis unbeschränkt.
                    </li>
                    <li>
                      Die Haftung im Falle von Beschaffenheitsgarantien nach dem
                      Produkt-Haftungsgesetz sowie für Personenschäden, d. h. für Verletzungen des
                      Lebens, des Körpers oder Gesundheit und Freiheit gilt unbeschränkt. Davon
                      umfasst ist auch eine unbeschränkte Haftung für arglistig verschwiegene
                      Mängel.
                    </li>
                    <li>
                      Bei leicht fahrlässiger Verletzung von wesentlichen Vertragspflichten
                      (Kardinalpflichten) gilt die Ersatzpflicht von Lendis uneingeschränkt.
                      Wesentliche Vertragspflichten sind Pflichten, deren Erfüllung die
                      ordnungsgemäße Durchführung des Vertrages erst ermöglicht und auf deren
                      Einhaltung der Kunde regelmäßig vertrauen darf. Im Übrigen ist die Haftung von
                      Lendis für leicht fahrlässig verursachte Schäden ausgeschlossen. Ziffer 6.2
                      dieser AGB bleibt davon unberührt.
                    </li>
                    <li>
                      Die vorstehenden Regelungen gelten entsprechend für die persönliche Haftung
                      der gesetzlichen Vertreter oder Personen, derer sich Lendis zur Erfüllung
                      ihrer Verpflichtungen bedient.
                    </li>
                    <li>
                      Eine Änderung der gesetzlichen Beweislastregelungen ist mit den vorstehenden
                      Regelungen nicht verbunden.
                    </li>
                    <li>
                      Alle Ansprüche des Kunden, die sich aus dem vorliegenden Vertragsverhältnis
                      ergeben, insbesondere Ansprüche auf Schaden- oder Aufwendungsersatz verjähren
                      in 12 Monaten nach Entstehen des Anspruchs. Im Falle von Schäden an Leben,
                      Körper, Gesundheit oder Freiheit einer Person, bei vorsätzlichem Handeln oder
                      wenn Lendis Mängel arglistig verschwiegen hat, sowie bei Ansprüchen aus dem
                      Produkthaftungsgesetz gelten die gesetzlichen Verjährungsfristen.
                    </li>
                  </ol>
                </li>

                <li>
                  <h4 className="uppercase font-semibold mt-4">Haftung des Kunden</h4>
                  <ol className="sub-list">
                    <li>
                      Vorbehaltlich der Ausnahmeregelungen in dieser Ziffer 7 sowie in nachstehender
                      Ziffer 8 haftet der Kunde für sämtliche Schäden an der Mietsache
                      (einschließlich deren Verlust, Abhandenkommen oder Untergang). Dies gilt
                      nicht, soweit der Schaden von Lendis oder ihren Erfüllungsgehilfen schuldhaft
                      verursacht wurde.
                    </li>
                    <li>
                      Die Abnutzung der Mietsache durch den vertragsgemäßen Gebrauch hat der Kunde
                      nicht zu vertreten.
                    </li>
                    <li>Der Kunde ist nicht berechtigt, die Mietsache weiterzuvermieten.</li>
                  </ol>
                </li>

                <li>
                  <h4 className="uppercase font-semibold mt-4">
                    Versicherungsschutz / Haftungsbefreiung
                  </h4>
                  <ol className="sub-list">
                    <li>
                      Abweichend von Ziffer 7 Satz 1 sind Kunden, die das Versicherungsschutzpaket
                      im Rahmen von „Lendis Care“ buchen, bei Eintritt eines versicherten
                      Schadensfalls im vereinbarten Umfang und mit Ausnahme der nachfolgend
                      beschriebenen Selbstbeteiligung von der Haftung für Schäden an der Mietsache
                      (einschließlich deren Verlust, Abhandenkommen oder Untergang) befreit (
                      <strong>„Haftungsbefreiung“</strong>). Die Selbstbeteiligung des Kunden
                      beträgt 10 % der Reparaturkosten im Reparaturfall bzw. 10 % der
                      unverbindlichen Preisempfehlung (UVP) der Mietsache im Falle eines Verlusts,
                      Abhandenkommens oder Totalschadens.
                      <br />
                      Soweit nicht anders vereinbart findet die Haftungsbefreiung ausschließlich auf
                      unter Ziffer 8.2 aufgelistete Schadensfälle Anwendung, welche die unter Ziffer
                      8.3 genannten Mietsachen betreffen und bei denen die Haftungsbefreiung nicht
                      nach Ziffer 8.4 ausgeschlossen ist.
                    </li>
                    <li>
                      Von der Haftungsbefreiung umfasste Schadensfälle sind:
                      <ol className="agb-head-sublist sub-list mx-4">
                        <li>
                          Beschädigung oder Zerstörung der Mietsache durch:
                          <ol className="agb-sublist sub-list mx-4">
                            <li>
                              Bedienungsfehler (z.B. durch Ungeschicklichkeit, Unwissenheit oder
                              aufgrund eines Missverständnisses),
                            </li>
                            <li>Bodenstürze, Bruchschäden, Flüssigkeitsschäden,</li>
                            <li>
                              Brand, Blitzschlag, Explosion, Implosion, Über-spannung, Induktion,
                              Kurzschluss,
                            </li>
                            <li>
                              Sabotage, Vandalismus und vorsätzliche wider-rechtliche Beschädigung
                              durch unberechtigte Dritte.
                            </li>
                          </ol>
                        </li>
                        <li>
                          Abhandenkommen des versicherten Gegenstandes durch:
                          <ol className="agb-sublist sub-list mx-4">
                            <li>
                              Diebstahl, sofern der Gegenstand in persönlichem Gewahrsam sicher
                              mitgeführt wurde,
                            </li>
                            <li>
                              Diebstahl, sofern der Gegenstand in einem verschlossenen, nicht
                              einsehbaren Behältnis in einem Beförderungsunternehmen (z. B.
                              Koffertransport im Zug) oder einer Gepäckaufbewahrung (z. B. als
                              Gepäck im Flugzeug) übergeben wurde,
                            </li>
                            <li>
                              Einbruchdiebstahl, sofern sich der Gegenstand in einem verschlossenen
                              Raum eines Gebäudes oder in einem verschlossenen, nicht einsehbaren
                              Bereich (z.B. Handschuhfach) eines verschlossenen PKWs befand,
                            </li>
                            <li>Raub.</li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li>
                      Von der Haftungsbefreiung umfasste Mietsachen sind:
                      <ol className="agb-sublist sub-list mx-4">
                        <li>Notebooks, Netbooks,</li>
                        <li>
                          mobile Geräte der Unterhaltungselektronik (u.a. mobile Musik- und
                          Filmwiedergabegeräte),
                        </li>
                        <li>
                          mobile Geräte zur Bildaufzeichnung (u.a. Foto-apparat, Kamera, Camcorder),
                        </li>
                        <li>
                          stationäre Anlagen der Unterhaltungselektronik (u.a. Fernseher,
                          HiFi-Gerät, Streaming Box),
                        </li>
                        <li>
                          stationäre Geräte der Präsentationstechnik (u.a. Projektor, Beamer),
                        </li>
                        <li>stationäre Geräte der Kommunikationstechnik und Telefonanlagen,</li>
                        <li>
                          stationäre PC-Systeme und PC-Komplettpakete inklusive aller im Paket
                          enthaltener Komponenten (u.a. Monitor, Drucker, Scanner, Maus, Tastatur),
                        </li>
                        <li>Handys, Smartphones, Smartwatches und Tablets,</li>
                        <li>
                          Möbel bzw. Büromöbel, u.a. Couches/Sofas, Tische, Sessel, Trennwände,
                          Kommoden, Regale, Hocker, Stühle, Betten, Nachttische, Lattenroste,
                          Spiegel, Lampen, Kleiderständer, Bürostühle, Telefonboxen (ohne
                          Gegenstände, die unter a,b,c,d,e,f,g, aufgelistet sind), Schreibtische,
                          Teppiche.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Eine Haftungsbefreiung besteht nicht bei:
                      <ol className="agb-sublist sub-list mx-4">
                        <li>
                          durch Krieg, Bürgerkrieg, kriegsähnliche Ereignisse, Aufruhr, innere
                          Unruhen, politische Gewalthandlungen, Attentate, Terrorakte, Streik,
                          Aussperrung oder Arbeitsunruhen, Enteignungen oder enteignungsgleiche
                          Eingriffe, Beschlagnahme, Entziehungen, Verfügungen, sonstige Eingriffe
                          von hoher Hand sowie Kernenergie verursachte Schäden,
                        </li>
                        <li>
                          Abhandenkommen des Gegenstandes ohne Fremdeinwirkung, z. B. durch
                          Liegenlassen, Vergessen und Verlieren,
                        </li>
                        <li>
                          durch dauernde Einflüsse des Betriebes und normale Abnutzung verursachte
                          Schäden,
                        </li>
                        <li>durch Witterungseinflüsse verursachte Schäden,</li>
                        <li>
                          durch nicht bestimmungsgemäße, insbesondere nicht den Herstellervorgaben
                          entsprechende Verwendung oder Reinigung des Gegenstandes verursachte
                          Schäden,
                        </li>
                        <li>
                          durch nicht fachgerechtes Einbauen, unsachgemäße Reparaturen, Service-,
                          Justierungs- und Reinigungsarbeiten oder sonstige Eingriffe durch den
                          Kunden oder einem nicht vom Lendis autorisierten Dritten verursachte
                          Schäden,
                        </li>
                        <li>
                          Schäden an Verschleißteilen und Verbrauchsmaterialien sowie Batterien und
                          Akkus,
                        </li>
                        <li>
                          durch Vögel, Nagetiere, Haustiere, Schädlinge und Ungeziefer aller Art
                          verursachte Schäden.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Der Kunde ist verpflichtet, Lendis einen Schadensfall unverzüglich anzuzeigen
                      und, soweit möglich und zumutbar, den Eintritt weitere Schäden zu verhindern.
                      Ferner hat der Kunde den eingetretenen Schaden in geeigneter Weise zu
                      dokumentieren (z.B. per Foto), den Schadenshergang gegenüber Lendis in
                      Textform zu beschreiben und allen zumutbaren Weisungen von Lendis hinsichtlich
                      Schadensaufklärung, Schadensminderung und Schadensbehebung Folge zu leisten.
                      Etwaige Nachfragen von Lendis zu den Umständen des Schadensfalls hat der Kunde
                      wahrheitsgemäß und vollständig zu beantworten. Schäden durch strafbare
                      Handlungen gegen das Eigentum hat der Kunde unverzüglich der Polizei
                      anzuzeigen und Lendis von der polizeilichen Anzeige eine Kopie zu übersenden.
                    </li>
                    <li>
                      Die Zahlung der vom Kunden zu tragenden Selbstbeteiligung erfolgt durch
                      Überweisung des Kunden. Der Überweisungsbetrag muss spätestens sieben Tage
                      nach Rechnungserhalt auf dem von Lendis angegebenen Bankkonto eingegangen
                      sein, es sei denn, zwischen den Parteien ist eine andere Zahlungsart oder ein
                      anderer Zahlungstermin vereinbart worden.
                    </li>
                  </ol>
                </li>

                <li>
                  <h4 className="uppercase font-semibold mt-4">Schlussbestimmungen</h4>
                  <ol className="sub-list">
                    <li>
                      Lendis ist berechtigt, diese AGB während der Laufzeit des Vertrages mit
                      Wirkung für die Zukunft zu ändern oder anzupassen. Lendis wird dem Kunden die
                      geänderten AGB vor dem geplanten Inkrafttreten in Textform übermitteln und auf
                      die Neuregelungen sowie das Datum des Inkrafttretens besonders hinweisen.
                      Zugleich wird Lendis dem Kunden eine angemessene, mindestens sechs Wochen
                      lange Frist für die Erklärung einräumen, ob er die geänderten
                      Nutzungsbedingungen für die weitere Inanspruchnahme der Leistungen akzeptiert.
                      Erfolgt innerhalb dieser Frist, welche ab Erhalt der Nachricht in Textform zu
                      laufen beginnt, keine Erklärung, so gelten die geänderten Bedingungen als
                      vereinbart. Lendis wird den Kunden bei Fristbeginn gesondert auf diese
                      Rechtsfolge, d.h. das Widerspruchsrecht, die Widerspruchsfrist und die
                      Bedeutung des Schweigens hinweisen. Dieser Änderungsmechanismus gilt nicht für
                      Änderungen der vertraglichen Hauptleistungspflichten der Parteien.
                    </li>
                    <li>
                      Sollte eine Bestimmung des geschlossenen Vertrages oder dieser AGB unwirksam
                      sein oder werden oder eine Lücke enthalten, so bleibt die Rechtswirksamkeit
                      der übrigen Bestimmungen davon unberührt.
                    </li>
                    <li>
                      Nebenabreden und Änderungen dieses Vertrages bedürfen der Schriftform. Dies
                      gilt auch für die Aufhebung des Schriftformerfordernisses.
                    </li>
                    <li>
                      Es gilt ausschließlich das Recht der Bundesrepublik Deutschland unter
                      Ausschluss des Deutschen internationalen Privatrechtes, insbesondere seiner
                      Kollisionsnormen und des UN-Kaufrechts (CISG). Ausschließlicher Gerichtsstand
                      für alle aus diesem Vertragsverhältnis sich ergebenden Streitigkeiten ist
                      Berlin.
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default ToS
